@import "src/styles/mixins";
@import "src/styles/shortcuts";

@keyframes slideLeft {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}

@keyframes slideRight {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
}

.BalloonLink {
  color: $red;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  transition: .2s ease-in-out;

  &:hover {
    color: $redActive;
    font-weight: bold;
  }
}

.MobilePopup {
  position: fixed;
  bottom: calc(66px + env(safe-area-inset-bottom, 20px));
  left: 8px;
  right: 8px;
  z-index: 1;
  background: $white;
  border-radius: 20px;
  padding: 16px;
  filter: drop-shadow(0px 2px 10px rgba(43, 46, 74, 0.1));

  &_Container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $headerLink;
      margin-bottom: 24px;
    }

    & > a {
      width: 100%;
      background: $red;
      border-radius: 8px;
      border: none;
      outline: none;
      color: $white;
      padding: 16px 16px 17px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      transition: .2s ease-in-out;
      @include BTN($red, $redActive);

      & > img {
        animation-name: slowlyRotate;
        animation-duration: 2s;
        width: 16px;
        height: 16px;
      }
    }

    &_Header {
      display: flex;
      margin-bottom: 8px;

      &_Close {
        background: $inputBack;
        border-radius: 50%;
        padding: 4px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;

        & > img {
          width: 16px;
          height: 16px;
        }
      }

      & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        color: $title;
        margin-right: 8px;
      }
    }
  }
}

.MobileBody {
  background-color: $white;

  &_PreList {
    overflow-y: auto;
    height: calc(100vh - 244px);
  }

  &_List {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &_Item {
      padding: 16px 20px;
      border-bottom: 1px solid $iconGrayBack;
      background: $white;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;

      & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        color: $title;
        margin-bottom: 12px;
      }

      & > p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $headerLink;
      }
    }

    &_NotFound {
      padding: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      letter-spacing: -0.01em;
      color: $blackText;
      text-align: center;
      line-height: 130%;
      box-shadow: 0 4px 10px rgba(43, 46, 74, 0.08);
    }

    &_FindMore {
      flex: 50 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;

      &:hover, &:active, &:focus {
        background-color: $inputBack;

        & > span {
          border-color: $red;
          color: $blackText;
          background: $white;
        }
      }

      & > span {
        background-color: $white;
        border-radius: 8px;
        border: 1px solid #E5E9EE;
        padding: 13px 8px 12px 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: black;
        transition: .2s ease-in-out;

        &:hover, &:active, &:focus {
          border-color: $red;
          color: $blackText;
          background: $white;
        }
      }
    }
  }

  &_Map {
    height: 650px;
    z-index: 1;
  }

  &_Filters {
    display: flex;
    height: 100%;
    flex-direction: column;
    background: $white;

    &_Container {
      display: flex;
      flex-direction: column;
      padding: 24px 20px;
      background: $white;
      height: 100%;

      &_Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        &_Counter {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $lightGrayText;

          & > span {
            & > span {
              color: $blackText;
            }
          }
        }

        &_Clean {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          text-align: right;
          color: $red;
        }
      }

      &_Body {
        &_Check {
          margin-bottom: 16px;

          & > label {
            position: relative;
            height: 24px;
            display: flex;
            cursor: pointer;

            & > input {
              outline: none;
              border: none;
            }

            & > span {
              margin-left: 32px;
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.01em;
              color: $blackText;
            }

            & > input + div:before {
              content: ' ';
              background: $white;
              border: 1px solid $lightGrayText;
              box-sizing: border-box;
              border-radius: 4px;
              width: 24px;
              height: 24px;
              position: absolute;
            }

            & > input:checked + div:before {
              content: ' ';
              background: $red;
              border-radius: 4px;
              border: 1px solid $lightGrayText;
              box-sizing: border-box;
              width: 24px;
              height: 24px;
              position: absolute;
              z-index: 0;
            }

            & > input:checked + div:after {
              content: url("/static/images/icons/acceptLastPlace.svg");
              width: 24px;
              height: 24px;
              position: absolute;
              z-index: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              vertical-align: middle;
              line-height: 0;
            }
          }

        }
      }
    }

    &_Agree {
      position: fixed;
      bottom: calc(56px + env(safe-area-inset-bottom, 20px));
      width: 100%;
      display: flex;
      align-items: center;

      & > button {
        margin-top: auto;
        width: 100%;
        padding: 18px 17px;
        border: none;
        outline: none;
        -webkit-appearance: none;
        background: $btnGreen;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $white;
        transition: .2s ease-in-out;
        @include BTN($btnGreen, $btnGreenActive);
      }
    }
  }
}

.BSPlank {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $iconGrayBack;
  padding: 24px;
  cursor: pointer;
  transition: .2s ease-in-out;
  background-color: white;

  &:hover {
    background-color: $inputBack;
  }

  &_Title {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 125%;
    color: $blackText;
  }

  &_Address {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $headerLink;
  }

  &_Wanted {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $btnGreen;
  }

  &_NotWanted {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $lightGrayText;
  }

  &_More {
    border: 1px solid $iconGrayBack;
    border-radius: 8px;
    padding: 13px 8px 12px 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $black;
    width: 50%;
    transition: .2s ease-in-out;
    background: $white;

    &:hover {
      border-color: $red;
      color: $black;
      background: $white;
    }

    &:focus {
      color: $red;
    }
  }
}

.MobileBloodStations {
  height: 100%;
  display: none;
  @include beMobile() {
    display: flex;
    flex-direction: column;
  }

  &_Body {
    height: 100%;
    position: static;
  }

  &_FilterHeader {
    background: $white;
    padding: 28px 20px 27px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(43, 46, 74, 0.08);

    &_Text {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: $blackText;
    }

    & > img {
      margin-left: auto;
    }
  }

  &_SearchHeader {
    background: $white;
    position: relative;

    &_Close {
      position: absolute;
      right: 20px;
      z-index: 100000;
      display: flex;
      align-items: center;
      margin-top: 16px;

      & > img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &_Header {
    padding: 12px 16px;
    background: $white;
    display: grid;
    grid-template-columns: auto 50px;
    column-gap: 16px;
    border-bottom: 1px solid rgba(43, 46, 74, 0.08);
    z-index: 1;

    &_Search {
      width: 100%;
      margin-right: 16px;

      &_Panel {
        background: $inputBack;
        border: 1px solid $iconGrayBack;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        max-height: 50px;

        & > img {
          margin-right: 12px;
        }

        & > span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $lightGrayText;
          width: 100%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
        }

        & > p {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $title;
        }
      }
    }

    &_Filters {
      width: 50px;
      height: 50px;
      background: $red;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &_Counter {
        position: absolute;
        right: -4px;
        top: -1px;
        background: $title;
        border-radius: 50%;
        padding: 3px 8px 4px 8px;

        & > span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: $white;
        }
      }
    }
  }

  &_Selector {
    background: $white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-shadow: 0 4px 10px rgba(43, 46, 74, 0.08);
    z-index: 1;
    height: 50px;

    &_SlideLeft {
      width: 100%;
      height: 2px;
      background-color: $red;
      transition: .2s ease-in-out;
      animation: slideLeft;
      animation-duration: .2s;
    }

    &_SlideRight {
      width: 100%;
      height: 2px;
      background-color: $red;
      transition: .2s ease-in-out;
      animation: slideRight;
      animation-duration: .2s;
    }

    &_Content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      height: 100%;
      position: relative;

      & > img {
        margin-right: 12px;
      }

      & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        margin-right: 8px;
      }


      & > div {
        background-color: $red;
        border-radius: 50%;

        & > div {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          & > span {
            color: $white;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            line-height: 1;
            margin: auto;
            width: 100%;
          }
        }
      }
    }

    &_Map {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;

      &Active {
        width: 100%;
        color: $red;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &_List {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;

      &Active {
        width: 100%;
        color: $red;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }

  &_Body {
    height: 600px;
    // position: relative;
    // z-index: 1;
  }
}

.CityItem, .BSItem {
  &_Block {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_Image {
      padding: 10px;
      background: $iconGrayBack;
      border-radius: 50%;
      margin-right: 12px;
    }

    &_Text {
      & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $blackText;
      }

      & > p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $lightGrayText;
      }
    }
  }
}

.Needs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  row-gap: 8px;
  min-width: 360px;

  div {
    text-align: center;
    border-radius: 4px;
    padding: 4px;
  }

  .unknown {
    border: 1px solid #27C175;
    color: #27C175;
    background-color: rgba(39, 193, 117, 0.1);
  }

  .need {
    border: 1px solid #F63E3E;
    color: #F63E3E;
    background-color: rgba(246, 62, 62, 0.1);
  }
}

// .MobileHeader {
//   background-color: #ffffff;

//   &_Filters {
//     display: flex;
//     height: 100%;
//     flex-direction: column;
//     background: $white;

//     &_Block {
//       display: flex;
//       flex-direction: column;
//       padding: 0 24px 24px 24px;

//       &_Header {
//         max-width: 585px;
//         display: flex;
//         justify-content: space-between;
//         margin-bottom: 24px;

//         @media (max-width: 1199px) {
//           max-width: 100%;
//         }

//         &_Selected {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 16px;
//           line-height: 19px;
//           color: $lightGrayText;
//           letter-spacing: -0.01em;

//           & > span {
//             & > span {
//               color: $blackText;
//               font-size: 18px;
              
//             }
//           }
//         }

//         &_Clean {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 16px;
//           line-height: 19px;
//           text-align: right;
//           color: $red;
//           cursor: pointer;
//           transition: .1s ease-in-out;

//           &:hover {
//             font-weight: 425;
//           }
//         }
//       }

//       &_Check {
//         margin-bottom: 16px;

//         & > label {
//           position: relative;
//           height: 24px;
//           display: flex;
//           cursor: pointer;

//           & > input {
//             outline: none;
//             border: none;
//           }

//           & > span {
//             margin-left: 32px;
//             display: flex;
//             align-items: center;
//             font-style: normal;
//             font-weight: normal;
//             font-size: 16px;
//             line-height: 19px;
//             letter-spacing: -0.01em;
//             color: $blackText;
//           }

//           & > input + div:before {
//             content: ' ';
//             background: $white;
//             border: 1px solid $lightGrayText;
//             box-sizing: border-box;
//             border-radius: 4px;
//             width: 24px;
//             height: 24px;
//             position: absolute;
//           }

//           & > input:checked + div:before {
//             content: ' ';
//             background: $red;
//             border-radius: 4px;
//             border: 1px solid $lightGrayText;
//             box-sizing: border-box;
//             width: 24px;
//             height: 24px;
//             position: absolute;
//             z-index: 0;
//           }

//           & > input:checked + div:after {
//             content: url("/static/images/icons/acceptLastPlace.svg");
//             width: 24px;
//             height: 24px;
//             position: absolute;
//             z-index: 0;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             vertical-align: middle;
//             line-height: 0;
//           }
//         }

//       }

//       &_Buttons {
//         display: flex;
//         margin-top: 16px;

//         &_Apply {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 16px;
//           line-height: 20px;
//           padding: 12px 24px;
//           color: $white;
//           background: $red;
//           border-radius: 4px;
//           outline: none !important;
//           border: none;
//           -webkit-appearance: none;
//           margin-right: 8px;
//           cursor: pointer;
//           transition: .2s ease-in-out;

//           &:hover, &:focus, &:active {
//             background: $redActive;
//           }

//           & > button {
//             outline: none !important;
//             border: none;

//             &:focus {
//               outline: none !important;
//               border: none;
//             }
//           }
//         }

//         &_Reject {
//           outline: none !important;
//           -webkit-appearance: none;
//           margin-right: 8px;
//           cursor: pointer;
//           font-style: normal;
//           font-weight: 500;
//           font-size: 16px;
//           line-height: 20px;
//           background: transparent;
//           color: $black;
//           padding: 12px 24px;
//           transition: .2s ease-in-out;
//           border: 1px solid $iconGrayBack;
//           border-radius: 4px;

//           &:hover {
//             border: 1px solid $red;
//             color: $black;
//           }

//           &:focus, &:active {
//             border: 1px solid $red;
//             color: $red;
//           }

//           & > button {
//             outline: none !important;
//             border: none;

//             &:focus {
//               outline: none !important;
//               border: none;
//             }
//           }
//         }
//       }
//     }
//   }

// }